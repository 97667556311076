import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    signal,
    ViewChildren,
    ViewContainerRef,
    ViewRef,
} from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { S25PricingAdjustmentComponent } from "../s25-pricing-adjustment/s25.pricing.adjustment.component";
import { Bind } from "../../../../decorators/bind.decorator";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25PricingRateGroupComponent } from "../s25-pricing-rate-group/s25.pricing.rate.group.component";
import { S25PricingOrganizationComponent } from "../s25-pricing-organization/s25.pricing.organization.component";
import { AccountProfileSubTotal, LineItemI, PricingOrg, UpdateData } from "../../../../pojo/Pricing";
import { PriceSheetI } from "../../../../pojo/RateScheduleI";
import { PricingService } from "../../../../services/pricing.service";
import { S25PricingSubtotalChargesComponent } from "../s25-pricing-subtotal-charges/s25.pricing.subtotal.charges.component";
import { S25PricingTaxComponent } from "./s25.pricing.tax.component";
import { S25PricingItemComponent } from "./s25.pricing.item.component";
import { S25PricingOccurrencesComponent } from "./s25.pricing.occurrences.component";
import { S25PricingTooltipComponent } from "./s25.pricing.tooltip.component";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { OptBean } from "../../../s25-opt/s25.opt.component";
import { PaymentService } from "../../s25-payments/payment.service";
import { S25PaymentsComponent } from "../../s25-payments/s25.payments.component";
import { TelemetryService } from "../../../../services/telemetry.service";
import { PricingOrgSummary } from "../s25-pricing-org-summary/s25.pricing.org.summary.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { S25Util } from "../../../../util/s25-util";
import { S25PricingUtil } from "../../s25.pricing.util";

@TypeManagerDecorator("s25-ng-pricing-orgs")
@Component({
    selector: "s25-ng-pricing-orgs",
    template: `@if (init) {
        <div [class.occ-view]="selectedView === 'occurrence'">
            @if (!summaryView && !noOccs) {
                <div class="c-margin-bottom--single c-margin-top--single">
                    <button
                        (click)="updateView('main')"
                        class="view-select-button c-margin-right--single"
                        [ngClass]="{
                            'aw-button aw-button--primary': !selectedView || selectedView === 'main',
                            'c-textButton': selectedView === 'occurrence',
                        }"
                    >
                        Line Item View
                    </button>
                    <button
                        (click)="updateView('occurrence')"
                        class="view-select-button"
                        [ngClass]="{
                            'aw-button aw-button--primary': selectedView === 'occurrence',
                            'c-textButton': !selectedView || selectedView === 'main',
                        }"
                    >
                        Occurrence View
                    </button>
                </div>
            }
            @for (list of orgLists; track list.orgId; let i = $index) {
                <div class="ngPricingOrgObj" [class.summary-view]="summaryView">
                    <div class="list-header-container">
                        <div
                            id="pricing_org_list_{{ list.eventId || list.data.eventId }}_{{ list.orgId }}"
                            class="pricing_header"
                            [class.table-visible]="paymentsMode && orgTableExpandMap()[list.orgId].isOpen"
                            [attr.aria-label]="
                                paymentsMode && orgTableExpandMap()[list.orgId].isOpen
                                    ? 'Collapse billing items for ' + list.orgName
                                    : paymentsMode
                                      ? 'Expand billing items for ' + list.orgName
                                      : 'Billing items for ' + list.orgName
                            "
                            tabindex="0"
                            (click)="toggleOrgTable(list.orgId)"
                            (keydown.enter)="toggleOrgTable(list.orgId)"
                        >
                            {{ list.orgName }}
                            @if (paymentsMode) {
                                <s25-ng-icon [type]="'caretUp'"></s25-ng-icon>
                            }
                        </div>
                        @if (paymentsMode && !summaryView) {
                            <div class="invoice-num-wrapper">
                                <label for="invoice-num-{{ list.orgId }}"> Invoice #:&nbsp;</label>
                                <s25-ng-editable-text
                                    [max]="80"
                                    [(val)]="list.invoiceNum"
                                    [hasCommit]="true"
                                    [hasCancelButton]="true"
                                    [readOnly]="!list.canEditInvoiceNum"
                                    [fieldId]="'invoice-num-' + list.orgId"
                                    [onCommit]="updateInvoiceNum"
                                    (valChange)="setInvoiceUpdateData(list.evBillId, list.orgId)"
                                ></s25-ng-editable-text>
                            </div>
                        }
                        <s25-ng-checkbox
                            class="hidden-rows-checkbox"
                            [ngClass]="{ hidden: !hiddenRows[i] }"
                            [disabled]="!hiddenRows[i]"
                            [modelValue]="!hiddenRows[i]"
                            (modelValueChange)="showRows(i)"
                            >Show Hidden Rows</s25-ng-checkbox
                        >
                    </div>
                    @if (!paymentsMode || orgTableExpandMap()[list.orgId].isOpen) {
                        <s25-ng-table
                            [caption]="list.caption"
                            [dataSource]="orgTableExpandMap()[list.orgId].config"
                            [pivotThresholdColumn]="null"
                        ></s25-ng-table>
                    }
                    <div class="pricing_invoice">
                        @if (paymentsEnabled && (list.evBillId || summaryView)) {
                            <s25-ng-payments
                                [amountInCents]="+list.total * 100"
                                [currency]="'usd'"
                                [productName]="'25Live Invoice'"
                                [productDescription]="'Event Invoice'"
                                [evBillId]="list.evBillId"
                                [orgId]="list.orgId || list.data.orgId"
                                [eventId]="list.eventId || list.data.eventId"
                                [summaryView]="summaryView"
                                [summaryData]="list.data.paymentSummaryData"
                                (onCreatePayment)="manualPaymentCreated.emit($event)"
                                (onNavToInvoice)="navToInvoice.emit($event)"
                            ></s25-ng-payments>
                        }
                        @if (!summaryView && list.hasFreshbooks && list.hasEventCharges > 0 && list.canEdit) {
                            <div>
                                <s25-ng-invoice
                                    [orgId]="list.orgId || list.data.orgId"
                                    [eventId]="list.eventId || list.data.eventId"
                                    [billId]="list.evBillId"
                                    [s25Invoices]="list.fbInvoiceData"
                                    [isSetMode]="optBean.combineRelatedEvents"
                                    [freshbooksOrgSummary]="freshbooksOrgSummary"
                                    [hasRelatedEvents]="list.hasRelatedEvents"
                                    [paymentsMode]="paymentsMode"
                                    (refreshInvoices)="invoiceUpdate()"
                                    (signalScroll)="scrollToOrg($event)"
                                ></s25-ng-invoice>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrgsComponent implements OnInit, AfterViewInit {
    @Input() orgLists: PricingOrg[];
    @Input() optBean: OptBean;
    @Input() summaryView: boolean;
    @Input() noOccs: boolean;
    @Input() freshbooksOrgSummary: PricingOrgSummary;
    @Input() paymentsMode: boolean;
    @Input() invoiceLocked: boolean;

    @Output() refreshPricing: EventEmitter<UpdateData | PriceSheetI | void> = new EventEmitter<
        UpdateData | PriceSheetI | void
    >();
    @Output() refreshOpt: EventEmitter<OptBean["selectedView"]> = new EventEmitter<OptBean["selectedView"]>();
    @Output() manualPaymentCreated: EventEmitter<number> = new EventEmitter<number>();
    @Output() navToInvoice: EventEmitter<number> = new EventEmitter<number>();

    @ViewChildren(S25TableComponent) orgTables: QueryList<S25TableComponent>;
    @ViewChildren(S25PaymentsComponent) paymentComps: QueryList<S25PaymentsComponent>;

    init: boolean = false;
    selectedView: OptBean["selectedView"];
    hiddenRows: { [key: number]: boolean } = {};
    occTableOpenRows: { [key: number]: { [key: number]: boolean } } = {};
    mainViewUpdated: boolean = null;
    columns: Table.Column[];
    paymentsEnabled: boolean;
    isPricingSet: boolean;
    tableRowId: number = 0;
    toggledTableOrgId: number;
    invoiceNumData: { billId: number; orgId: number };
    orgTableExpandMap = signal<{ [key: number]: { isOpen: boolean; config: Table.DataSource } }>({});
    openTableOrgIds = computed(() =>
        Object.keys(this.orgTableExpandMap()).filter((orgId) => this.orgTableExpandMap()[parseInt(orgId)].isOpen),
    );
    private tableCompMap: Map<number, S25TableComponent> = new Map();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private cd: ChangeDetectorRef,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.paymentsEnabled =
            this.orgLists[0]?.data.fls.EVENT_BILLING === "F" && this.orgLists[0]?.data.fls.MANAGE_PAY !== "N";

        this.isPricingSet = this.orgLists[0]?.evBillId ?? this.orgLists[0]?.data.evBillId;

        this.selectedView = this.optBean.selectedView;

        this.initTableConfigs();
        this.init = true;
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        if (this.paymentsMode) {
            // store references to table components by orgId in payments mode when expanded
            this.orgTables.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((tables) => {
                if (this.toggledTableOrgId) {
                    // update table refs to map when toggled
                    if (this.orgTableExpandMap()[this.toggledTableOrgId]) {
                        this.tableCompMap.set(this.toggledTableOrgId, tables.last);
                    } else {
                        this.tableCompMap.delete(this.toggledTableOrgId);
                    }
                    this.toggledTableOrgId = null;
                } else {
                    // update table refs to map when soft/opt bar refresh
                    this.updateTableCompMap();
                }
            });
        } else {
            // in standard pricing, no expand/collapse functionality, so store all table components
            this.orgLists.forEach((list: PricingOrg, index: number) => {
                const orgTableComp = this.orgTables.get(index);
                if (orgTableComp) this.tableCompMap.set(list.orgId, orgTableComp);
            });
        }
    }

    @Bind
    refreshTable(pricingUpdateData: UpdateData) {
        let index: number;
        const found = this.orgLists.find((list: PricingOrg, i: number) => {
            index = i;
            return list.orgId === pricingUpdateData.orgId;
        });
        // table moving to doesn't exist - refresh to create
        if (!found) {
            pricingUpdateData.combineRelatedEvents
                ? this.refreshOpt.emit(this.selectedView)
                : this.refreshPricing.emit();
            return;
        }

        if (pricingUpdateData.newChargeToId && pricingUpdateData.newChargeToId !== pricingUpdateData.orgId) {
            let oldListIndex: number;
            const oldList = this.orgLists.find((list: PricingOrg, i: number) => {
                oldListIndex = i;
                return list.orgId === pricingUpdateData.newChargeToId;
            });

            if (
                !oldList ||
                !pricingUpdateData[pricingUpdateData.orgId] ||
                !pricingUpdateData[pricingUpdateData.newChargeToId]
            ) {
                pricingUpdateData.combineRelatedEvents
                    ? this.refreshOpt.emit(this.selectedView)
                    : this.refreshPricing.emit();
                return;
            }

            this.updateTable(oldList, pricingUpdateData[oldList.orgId], oldListIndex);

            this.reopenOccTable(oldList);
        }

        found.data.isAdjustment = true;

        this.mainViewUpdated = this.selectedView === "main" || this.selectedView === undefined;

        this.updateTable(found, pricingUpdateData[found.orgId], index);

        this.reopenOccTable(found);

        this.refreshPricing.emit(pricingUpdateData);
    }

    @Bind
    initTableConfigs() {
        if (this.selectedView === "main" || (this.mainViewUpdated && this.selectedView === "occurrence")) {
            //if main view is chosen after the occurrence view has been accessed, soft refresh to get up-to-date data
            if (this.selectedView === "main") this.selectedView = null;
            if (this.mainViewUpdated) this.mainViewUpdated = null;

            this.refreshOpt.emit(this.selectedView);
            return;
        }

        this.setColumns();

        this.orgLists.forEach((list: PricingOrg, i: number) => {
            this.hiddenRows[i] = false;
            this.occTableOpenRows[list.orgId] = {};
            list.data.isSummary = this.summaryView;

            const promise = async () => {
                const paymentData =
                    this.paymentsEnabled &&
                    list.evBillId &&
                    (await PaymentService.getFormattedPayments(list.evBillId, list.orgId, list.total));
                if (paymentData) list.data.paymentData = { ...paymentData, orgId: list.orgId };
                Object.assign(list.data, {
                    occView: this.selectedView === "occurrence",
                    hideNoChargeItems: this.optBean.hideNoChargeItems,
                });
                let data: any;
                if (this.selectedView === "occurrence") {
                    data = [
                        ...S25PricingUtil.getPricingOccurrenceList(list.data),
                        ...list.rowFuncs.getFooter(list.data),
                    ];
                } else {
                    //if no view selected, initial navigation to page - set up tables
                    data = [...list.rowFuncs.getRows(list.data), ...list.rowFuncs.getFooter(list.data)];
                }

                return data;
            };

            const getData = async () => {
                const data: any = await promise();

                return {
                    rows: data.map(this.mapToRows),
                };
            };

            const tableConfig: Table.DataSource = {
                type: "unpaginated",
                dataSource: getData,
                columns: this.columns,
            };

            this.orgTableExpandMap.update((map) => ({
                ...map,
                [list.orgId]: { ...map[list.orgId], config: tableConfig },
            }));

            if (this.orgTableExpandMap()[list.orgId]?.isOpen || !this.paymentsMode) {
                return this.onTableUpdate(list.orgId, tableConfig);
            }
        });
    }

    setColumns() {
        this.columns = [
            ...(this.optBean.combineRelatedEvents ? [{ id: "reference", header: "Reference" }] : []),
            { id: "item", header: "Item" },
            { id: "listPrice", header: "List Price", width: 120 },
            { id: "adjust", header: "Adjustments" },
            { id: "price", header: "Price", width: 120 },
            { id: "tax", header: "Taxes" },
            { id: "total", header: "Total", width: 120 },
            ...(this.summaryView ? [{ id: "invoice", header: "Invoice To" }] : []),
            { id: "charge", header: "Charge To", maxWidth: 250 },
            { id: "schedule", header: "Rate Schedule" },
            { id: "group", header: "Rate Group", maxWidth: 250 },
            { id: "debit", header: "Debit Account" },
            { id: "credit", header: "Credit Account" },
        ];
    }

    @Bind
    mapToRows(item: any): Table.Row {
        const multEventsMod = this.optBean.combineRelatedEvents ? 1 : 0; //Adds a new column 'Reference' when combine related events is chosen
        const invoiceToMod = this.summaryView ? 1 : 0; //Adds a new column 'Invoice To' when occurrence view or invoice summary view is chosen

        return {
            id: ++this.tableRowId,
            name: item.itemName,
            cells: {
                ...(this.optBean.combineRelatedEvents && {
                    reference: { text: item.row[0] },
                }),
                item: {
                    ...(item.row[0 + multEventsMod] === "Profile Entries:" ||
                    item.row[0 + multEventsMod] === "Adjustments:" ||
                    item.row[0 + multEventsMod] === "Occurrence Line Item Adjustments" ||
                    item.row[0 + multEventsMod] === "Event Adjustments" ||
                    item.row[0 + multEventsMod] === "Subtotal" ||
                    item.row[0 + multEventsMod] === "Total"
                        ? { text: item.row[0 + multEventsMod] }
                        : {
                              component: S25PricingItemComponent,
                              inputs: {
                                  modelBean: item.row[0 + multEventsMod],
                                  isExpandable: item.row[0 + multEventsMod].reservations?.length > 0,
                              },
                              outputs: {
                                  showData: (lineItemData: Table.NewRowModel) => {
                                      this.addOccDataRow(lineItemData);
                                  },

                                  signalHiddenRows: (changedTable: HTMLTableElement) => {
                                      const tables = this.elementRef.nativeElement.querySelectorAll("table");

                                      for (let i = 0; i < tables.length; i++) {
                                          if (tables[i] === changedTable) {
                                              this.hiddenRows[i] = true;
                                          }
                                      }

                                      this.cd.detectChanges();
                                  },
                              },
                          }),
                },
                listPrice: {
                    ...(item.row[0 + multEventsMod] === "Profile Entries:"
                        ? { component: S25PricingTooltipComponent, inputs: { data: item.row[1 + multEventsMod] } }
                        : {
                              text:
                                  item.row[0 + multEventsMod] === "Adjustments:" ||
                                  item.row[0 + multEventsMod] === "Event Adjustments" ||
                                  item.row[0 + multEventsMod] === "Occurrence Line Item Adjustments"
                                      ? item.row[1 + multEventsMod]
                                      : S25PricingUtil.formatCurrency(item.row[1 + multEventsMod]),
                          }),
                },
                adjust: {
                    ...(item.row[0 + multEventsMod] === "Subtotal" ||
                    item.row[0 + multEventsMod] === "Total" ||
                    item.row[0 + multEventsMod] === "Event Adjustments" ||
                    item.row[0 + multEventsMod] === "Occurrence Line Item Adjustments"
                        ? { text: item.row[2 + multEventsMod] }
                        : {
                              component: S25PricingAdjustmentComponent,
                              inputs: {
                                  modelBean: Object.assign(item.row[2 + multEventsMod], {
                                      summaryView: this.summaryView,
                                  }),
                                  invoiceLocked: this.invoiceLocked,
                              },
                              outputs: {
                                  updateAdjustment: (pricingUpdate) => {
                                      this.refreshTable(pricingUpdate);
                                  },
                              },
                          }),
                },
                price: {
                    text:
                        item.row[0 + multEventsMod] === "Profile Entries:" ||
                        item.row[0 + multEventsMod] === "Adjustments:" ||
                        item.row[0 + multEventsMod] === "Event Adjustments" ||
                        item.row[0 + multEventsMod] === "Occurrence Line Item Adjustments"
                            ? item.row[3 + multEventsMod]
                            : S25PricingUtil.formatCurrency(item.row[3 + multEventsMod]),
                },
                tax: {
                    ...(item.row[0 + multEventsMod] === "Adjustments:" ||
                    item.row[0 + multEventsMod] === "Subtotal" ||
                    item.row[0 + multEventsMod] === "Total"
                        ? { text: item.row[4 + multEventsMod] }
                        : item.row[0 + multEventsMod] === "Profile Entries:"
                          ? { component: S25PricingTooltipComponent, inputs: { data: item.row[4 + multEventsMod] } }
                          : {
                                component: S25PricingTaxComponent,
                                inputs: { taxData: item.row[4 + multEventsMod].tax },
                            }),
                },
                total: {
                    ...(item.row[0 + multEventsMod] === "Profile Entries:" ||
                    item.row[0 + multEventsMod] === "Adjustments:"
                        ? {
                              component: S25PricingSubtotalChargesComponent,
                              inputs: { modelBean: item.row[5 + multEventsMod] },
                          }
                        : { text: S25PricingUtil.formatCurrency(item.row[5 + multEventsMod]) }),
                },
                ...(this.summaryView && {
                    invoice: { text: item.row[6 + multEventsMod] },
                }),
                charge: {
                    component: S25PricingOrganizationComponent,
                    inputs: {
                        modelBean: item.row[6 + multEventsMod + invoiceToMod],
                        invoiceLocked: this.invoiceLocked,
                    },
                    outputs: {
                        updateOrg: (pricingUpdate) => {
                            this.refreshTable(pricingUpdate);
                        },
                    },
                },
                schedule: { text: item.row[7 + multEventsMod + invoiceToMod] ?? "" },
                group: {
                    component: S25PricingRateGroupComponent,
                    inputs: {
                        modelBean: item.row[8 + multEventsMod + invoiceToMod],
                        invoiceLocked: this.invoiceLocked,
                    },
                    outputs: {
                        updateRateGroup: (rateGroupUpdate) => {
                            rateGroupUpdate.isRateUpdate = true;
                            this.refreshTable(rateGroupUpdate);
                        },
                    },
                },
                debit: { text: item.row[9 + multEventsMod + invoiceToMod] ?? "" },
                credit: { text: item.row[10 + multEventsMod + invoiceToMod] ?? "" },
            },
        };
    }

    refreshAllRateGroups(rateData: PriceSheetI) {
        this.orgLists.forEach((list: PricingOrg, index: number) => {
            const newData = rateData[list.orgId];

            newData.rows.map((row: LineItemI, i: number) => {
                row.rate_name = list.data.rows[i]?.rate_name;
                return row;
            });

            this.updateTable(list, newData, index);
        });

        this.refreshPricing.emit(rateData);
    }

    scrollToOrg(orgData: string) {
        const orgTable = this.elementRef.nativeElement.querySelector(orgData);
        orgTable?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    }

    updateColumns() {
        const colList = this.optBean.colChooseBean.colList;
        this.orgTables.forEach((table) => {
            const selectedColumns = colList.filter((col) => col.isVisible);
            if (table.visibleColumns?.length !== selectedColumns?.length) {
                table.visibleColumns = table.dataSource.columns.filter(
                    (tableCol: any) =>
                        colList.find((selectItem: any) => selectItem.prefname === tableCol.id)?.isVisible,
                );

                return table.refresh();
            }
        });
    }

    updateView(selectedView: OptBean["selectedView"]) {
        let eventType = selectedView === "occurrence" ? "OccView" : "InlineView";
        TelemetryService.sendWithSub("Pricing", "Event", eventType);
        this.selectedView = selectedView;
        this.cd.detectChanges();
        this.initTableConfigs();
        this.updateColumns();
    }

    addOccDataRow(lineItem: Table.NewRowModel) {
        const tableEl = lineItem.table;

        if (lineItem.action === "create") {
            const occTable = tableEl.insertRow(lineItem.rowIndex + 1);
            occTable.ariaLabel = "Occurrence Breakdown Table";
            occTable.tabIndex = 0;
            occTable.role = "listitem";
            lineItem.row.style.borderBottom = "unset";

            this.occTableOpenRows[lineItem.data.charge_to_id][lineItem.rowIndex] = true;

            const cell = this.renderer.createElement("td");
            cell.colSpan = this.optBean.colChooseBean.colList.filter((col) => col.isVisible)?.length;

            this.renderer.appendChild(occTable, cell);

            const componentRef = this.viewContainerRef.createComponent(S25PricingOccurrencesComponent);

            this.renderer.appendChild(cell, componentRef.location.nativeElement);

            lineItem.data = Object.assign(lineItem.data, {
                occView: this.selectedView === "occurrence",
                isSummaryView: this.summaryView,
                combineRelatedEvents: this.optBean.combineRelatedEvents,
            });

            componentRef.instance.data = {
                occData: lineItem.data,
                columns: this.columns.filter(
                    (col) =>
                        this.optBean.colChooseBean.colList.find((selectItem) => selectItem.prefname === col.id)
                            ?.isVisible,
                ),
                rowFunc: this.orgLists[0].rowFuncs.getRows,
                mapToRows: this.mapToRows,
            };
            componentRef.instance.ngOnInit();
        } else {
            tableEl.deleteRow(lineItem.rowIndex + 1);
            this.occTableOpenRows[lineItem.data.charge_to_id][lineItem.rowIndex] = false;
            lineItem.row.style.borderBottom = "1px solid #e5e5e5";
        }
    }

    showRows(listIndex: number) {
        const rows = this.elementRef.nativeElement.querySelectorAll("table")[listIndex].children[2].children;

        for (let row of rows) {
            if (row.classList.contains("ngHidden")) {
                this.renderer.removeClass(row, "ngHidden");
            }
        }

        this.hiddenRows[listIndex] = false;
        setTimeout(() => {
            !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        });
    }

    invoiceUpdate() {
        this.refreshOpt.emit(this.selectedView);
    }

    reopenOccTable(orgList: PricingOrg) {
        if (this.selectedView === "occurrence" && Object.keys(this.occTableOpenRows).length > 0) {
            setTimeout(() => {
                const expandButtons = this.elementRef.nativeElement.querySelectorAll(
                    `.occ-expand-button-${orgList.orgId}`,
                );

                expandButtons.forEach((button: HTMLButtonElement, i: number) => {
                    if (this.occTableOpenRows[orgList.orgId][i + 1]) {
                        button.click();
                    }
                });
            });
        }
    }

    async updatePaymentData(listData: any, index: number, updateData: UpdateData[number]) {
        const newTotalInCents = +updateData.footerData.total.row[5] * 100;

        listData.paymentData.remainingBalance = (newTotalInCents - listData.paymentData.totalPayments) / 100;

        updateData.footerData.total.row[6] = listData.paymentData;
        const paymentComp = this.paymentComps.get(index);
        paymentComp.amountInCents = newTotalInCents;
        await paymentComp.reset();
    }

    async tableDataFunc(orgList: PricingOrg, updateData: any, index: number) {
        if (!updateData) {
            updateData.combineRelatedEvents ? this.refreshOpt.emit(this.selectedView) : this.refreshPricing.emit();
            return;
        }

        orgList.data.rows = updateData.rows;
        orgList.data.occSubtotals = updateData.occSubtotals;
        orgList.data.occView = this.selectedView === "occurrence";

        if (updateData.profileSubtotals)
            orgList.data.profileSubtotals = updateData.profileSubtotals.filter(
                (profile: AccountProfileSubTotal) => profile.chargeToId === orgList.data.orgId,
            );

        orgList.data.paymentData && (await this.updatePaymentData(orgList.data, index, updateData));

        return [
            ...(this.selectedView === "occurrence"
                ? S25PricingUtil.getPricingOccurrenceList(orgList.data)
                : orgList.rowFuncs.getRows(orgList.data)),
            updateData.footerData.subtotal,
            ...updateData.footerData.adjustments,
            updateData.footerData.total,
        ];
    }

    updateTable(orgList: PricingOrg, updateData: any, index: number) {
        const getData = async () => {
            const data: any = await this.tableDataFunc(orgList, updateData, index);

            return {
                rows: data.map(this.mapToRows),
            };
        };

        this.setColumns();

        const newConfig: Table.DataSource = {
            type: "unpaginated",
            dataSource: getData,
            columns: this.columns,
        };

        this.orgTableExpandMap.update((map) => ({ ...map, [orgList.orgId]: { isOpen: true, config: newConfig } }));
        return this.onTableUpdate(orgList.orgId, newConfig);
    }

    toggleOrgTable(orgId: number) {
        if (!this.paymentsMode) return;
        this.orgTableExpandMap.update((map) => ({ ...map, [orgId]: { ...map[orgId], isOpen: !map[orgId].isOpen } }));
        this.toggledTableOrgId = orgId;
        setTimeout(() => {
            this.updateColumns();
        }, 50);
    }

    updateTableCompMap() {
        if (this.openTableOrgIds().length) {
            this.orgTables.forEach((table, index) => {
                this.tableCompMap.set(parseInt(this.openTableOrgIds()[index]), table);
            });
        }
    }

    onTableUpdate(orgId: number, config: Table.DataSource) {
        const tableToUpdate = this.tableCompMap.get(orgId);
        if (tableToUpdate) {
            tableToUpdate.dataSource = config;
            return tableToUpdate.refresh();
        }
    }

    @Bind
    async updateInvoiceNum(newInvoiceNum: string) {
        const { billId, orgId } = this.invoiceNumData;
        const [_, error] = await S25Util.Maybe(PricingService.putInvoiceId(billId, orgId, newInvoiceNum));
        if (error) return S25Util.showError(error);
        this.invoiceNumData = null;
    }

    setInvoiceUpdateData(evBillId: number, orgId: number) {
        this.invoiceNumData = { billId: evBillId, orgId: orgId };
    }
}
